/* App.css */
.App {
  text-align: center;
}

select {
  margin: 20px;
  padding: 10px;
}

.map-container {
  height: 80vh;
  width: 100%;
}

/* Hide mobile layout by default */
.mobile-layout {
  display: none;
}

/* Hide desktop layout on mobile screens */
@media (max-width: 768px) {
  .desktop-layout {
    display: none;
  }

  .mobile-layout {
    display: block;
  }
}

